<template>
    <div align="center">
        <br>
        <div align="center">
            <span class="text-h4">Menu gestione Pratica</span>
            <hr>
        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-12 col-md-4 q-pr-md" align="left">
                <h5 class="q-mt-sm q-mb-sm">Dati generali pratica</h5>
                    <q-list  separator>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Cliente:</q-item-label>
                                <q-item-label caption>{{RecordSelezionato.name}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Stato della pratica:</q-item-label>
                                <q-item-label caption>{{getStatoPratica}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Numero pratica:</q-item-label>
                                <q-item-label caption>{{RecordSelezionato.numero}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Numero interno:</q-item-label>
                                <q-item-label caption>{{RecordSelezionato.numero_interno}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Data di decorrenza richiesta:</q-item-label>
                                <q-item-label caption>{{getDataDecorrenzaPratica}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Note:</q-item-label>
                                <q-item-label caption>{{annotazione}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Unità operativa richiedente:</q-item-label>
                                <q-item-label caption>{{RecordSelezionato.unita_operativa}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Persona operativa:</q-item-label>
                                <q-item-label caption>{{RecordSelezionato.operatore}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Telefono Ufficio #1:</q-item-label>
                                <q-item-label caption>{{dati_ufficio.numero_telefono1}}</q-item-label>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Telefono Ufficio #2:</q-item-label>
                                <q-item-label caption>{{dati_ufficio.numero_telefono2}}</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Email Ufficio #2:</q-item-label>
                                <q-item-label caption>{{dati_ufficio.email}}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                    <br>
                    <div v-show="haveDatiEconomici">
                        <h5 class="q-mt-sm q-mb-sm">Importo totale Pratica</h5>
                        <q-list  separator>
                            <q-item>
                                <q-item-section>
                                    <q-item-label style="font-size: 32px;">{{getPremioTotalePratica}} Euro</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <br>
                    <!--    <h5 class="q-mt-sm q-mb-sm">Dati economici</h5>
                        <q-list  separator>
                            <q-item v-for="(dato_economico,index) in getDatiEconomiciOrdinati" v-bind:key="index">
                                <q-item-section>
                                    <q-item-label>Tariffa</q-item-label>
                                    <q-item-label caption>{{dato_economico.tariffa}}</q-item-label>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{dato_economico.tipo.replaceAll("_"," ")}}</q-item-label>
                                    <q-item-label caption>{{dato_economico.importo}} Euro</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list> //-->
                    </div>
                <br><br>
            </div>

            <div class="col-12 col-md-6 row" align="center">
                <!--<div class="col-12 col-md-12"> //-->
                    <fieldset style="width: 100%;" class="q-mb-md">
                        <legend class="q-pr-md q-pl-md"> Informazioni </legend>
                        <br>
                        <QQButton label="Scheda della Pratica" color="blue-grey" icon="mdi-card-account-details-outline" size="md" @click.native="onClickSchedaPratica()"/>
                        <QQButton :disabled="PraticaAnnullata" label="Problemi segnalati" color="blue-grey" icon="mdi-grid-off" size="md" @click.native="onProblemiSegnalati()"/>
                        <QQButton :disabled="!PraticaDefinita" label="Documenti" color="blue-grey" icon="mdi-briefcase-upload-outline" size="md" @click.native="onDocumentiContratto()"/>
                        <QQButton label="Pagamenti e Ricevute" color="blue-grey" icon="mdi-credit-card-check-outline" size="md" @click.native="onPagamentiRicevute()"/>
                    </fieldset>

                    <fieldset class="q-mb-md" style="width: 100%;">
                        <legend class="q-pr-md q-pl-md"> Procedure </legend>
                        <br>
                        <QQButton :disabled="PuoControllareRichiestaEmissione" v-if="isSede" label="Controlla richiesta emissione" color="blue-grey" icon="mdi-file-tree" size="md" @click.native="onControlloRichiestaEmissione()"/>
                        <QQButton label="Rigenera Dichiarazioni Contraente" color="blue-grey" icon="mdi-file-tree" size="md" @click.native="onRigeneraDichiarazioniContraente()"/>
                        <QQButton :disabled="!PraticaInLavorazione" v-if="isSede" label="Definisci pratica" color="blue-grey" icon="mdi-check-all" size="md" @click.native="onDefinisciPratica()"/>
                        <QQButton label="Segnala problema a Sede" color="blue-grey" icon="mdi-flag-checkered" size="md" @click.native="onSegnalaProblemiASede()"/>
                        <QQButton :disabled="!PraticaDefinita" v-if="PuoLavorareLaPratica()" label="Rinnova" color="blue-grey" icon="mdi-refresh" size="md" @click.native="onInLavorazione()"/>
                        <QQButton v-if="PuoLavorareLaPratica()" label="Annulla Pratica" v-show="PuoCancellareLaPratica()" color="blue-grey" icon="mdi-delete" size="md" @click.native="onCancellaPratica()"/>
                        <QQButton v-if="PuoLavorareLaPratica()" label="Gestione Contratti" v-show="PuoCancellareLaPratica()" color="blue-grey" icon="mdi-format-list-checks" size="md" @click.native="onInLavorazione()"/>
                        <QQButton v-if="PuoLavorareLaPratica()" label="Cambia stato Pratica" color="blue-grey" icon="repeat-variant" size="md" @click.native="onClickCambiaStatoPratica()"/>
                    </fieldset>

            </div>

            <div class="col-12 col-md-12">
                <q-table
                    title="Elenco dei contratti"
                    :data="elenco_contratti"
                    :columns="colonne_contratti"
                    row-key="prodotto_id"
                    wrap-cells
                >
                    <template v-slot:header="props">
                        <q-tr :props="props">
                        <q-th auto-width />
                        <q-th
                            v-for="col in props.cols"
                            :key="col.name"
                            :props="props"
                        >
                            {{ col.label }}
                        </q-th>
                        </q-tr>
                    </template>

                    <template v-slot:body="props">
                        <q-tr :props="props">
                            <q-td auto-width>
                                <q-btn size="sm" color="accent" round dense @click="props.expand = !props.expand" :icon="props.expand ? 'remove' : 'add'" />
                            </q-td>

                            <q-td>{{ props.cols[0].value }}</q-td>
                            <q-td>{{ props.cols[1].value }}</q-td>
                            <q-td>{{ TranscodificaData(props.cols[2].value) }}</q-td>
                            <q-td>{{ TranscodificaData(props.cols[3].value) }}</q-td>

                        </q-tr>
                        <q-tr v-show="props.expand" :props="props">
                            <q-td colspan="100%">
                                <div class="text-left">Quietanze su contratto {{ props.row.prodotto_id }}.</div>
                                <br>
                                <table class="table table-bordered">

                                    <tbody>
                                        <tr>
                                            <td style="font-weight: bold;">Fornitore</td>
                                            <td style="font-weight: bold;">Decorrenza Rata</td>
                                            <td style="font-weight: bold;">Scadenza Rata</td>
                                            <td style="font-weight: bold;">Data di Incasso</td>
                                            <td style="font-weight: bold;">Costo Rata</td>
                                            <td style="font-weight: bold;">Incasso Rata</td>
                                            <td v-if="isSede"></td>
                                            <td>Ricevuta pagamento Quietanza</td>
                                        </tr>
                                        <tr v-for="(quietanza,index) in elencoQuietanze(props.row.prodotto_id)" v-bind:key="index">
                                            <td>{{ quietanza.fornitore }}</td>
                                            <td align="center">{{ TranscodificaData(quietanza.decorrenza) }}</td>
                                            <td align="center">{{ TranscodificaData(quietanza.scadenza) }}</td>
                                            <td align="center">{{ TranscodificaData(quietanza.data_incasso) }}</td>
                                            <td align="right">{{ quietanza.premio }} Euro</td>
                                            <td align="right">{{ quietanza.totale }} Euro</td>
                                            <td align="center" width="220" v-if="isSede">
                                                <q-btn
                                                    v-if="quietanza.da_definire"
                                                    style="width: 200px;"
                                                    color="deep-orange"
                                                    glossy
                                                    label="DEFINISCI"
                                                    @click.native="onDefinisciQuietanza(props.cols[1].value,quietanza)"
                                                />
                                            </td>
                                            <td align="center" width="220">
                                                <q-btn
                                                    style="width: 200px;"
                                                    color="deep-orange"
                                                    glossy
                                                    label="Stampa Ricevuta"
                                                    @click.native="onStampaRicevuta(props.cols[1].value,quietanza)"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </q-td>
                        </q-tr>
                    </template>

                </q-table>

            </div>

        </div>
        <br><br><hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />
            </div>
        </div>
        <br><br><br><br><br>
    </div>
</template>

<script>
    import axios from 'axios';
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";
    import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapState, mapActions } from "vuex";
    import { exportFile } from 'quasar';

    export default {
        name: "MenuGestionePratiche",
        data() {
            return {
                annotazione: "",
                dati_economici: [],
                dati_ufficio: {},
                elenco_contratti_quietanze: [],
                colonne_contratti:  [
                    { name: 'prodotto_id',required: true,label: 'Tariffa', align: 'left', field: "prodotto_id", sortable: true },
                    { name: 'compagnia',label: 'Compagnia', align: 'left', field: "compagnia", sortable: true },
                    { name: 'decorrenza',label: 'Decorrenza Contrattuale', align: 'left', field: "decorrenza", sortable: true },
                    { name: 'scadenza',label: 'Scadenza Contrattuale', align: 'left', field: "scadenza", sortable: true }
                ]
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {
                RecordSelezionato: "RecordSelezionato",
                dati_cliente: "dati_cliente"
            }),
            ...mapFields("gestioneRinnovi", {
                quietanza_da_rinnovare: "quietanza_da_rinnovare"
            }),
            ...mapState({
                formPratica: state => state.gestionePratiche.formPratica,
                formPreventivo: state => state.formPreventivo
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            },
            elenco_contratti() {
                return this.onMapContratti(this.elenco_contratti_quietanze);
            },
            haveDatiEconomici() {
                return (this.dati_economici.length > 0) && (this.getStatoPratica === "DEFINITA");
            },
            PraticaInLavorazione() {
                return this.getStatoPratica === "IN LAVORAZIONE";
            },
            PraticaDefinita() {
                return this.getStatoPratica === "DEFINITA";
            },
            PraticaAnnullata() {
                return (this.getStatoPratica === "ANNULLATA") ||
                       (this.getStatoPratica === "DEFINITA");
            },
            PuoControllareRichiestaEmissione() {
                var stato = this.getStatoPratica;

                stato = stato.replace(/_/g," ");

                //console.log("stato:",stato);

                return ! ((stato.toUpperCase() === "IN ATTESA DI LAVORAZIONE") && (this.isSede));
            },
            getDataDecorrenzaPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.decorrenza;
            },
            getStatoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                var stato = this.formPratica.stato_pratica.toUpperCase();
                stato = stato.replace(/_/g," ");
                return stato;
            },
            getIDCliente() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.idCliente;
            },
            getPremioTotalePratica() {
                var premio = 0;
                var dati = this.dati_economici.filter(d => {
                    return d.da_sommare === true;
                });

                for (var i=0;i<dati.length;i++) {
                    premio += dati[i].importo;
                }

                return premio.toFixed(2);
            },
            getDatiEconomiciOrdinati() {
                var dati = this.dati_economici;
                return dati.sort((a) => {
                    let fa = a.tipo.toLowerCase();
                    //let fb = b.tipo.toLowerCase();

                    switch(fa) {
                        case "premio_lordo_polizza": return -1;
                        case "provvigioni_polizza": return 1;
                    }

                    return 1;
                });
            },
        },
        methods: {
            onMapContratti(contratti) {
                if (commonLib.isEmpty(contratti)) return [];

                return contratti.map(contratto => {
                    return {
                        prodotto_id: contratto.prodotto_id,
                        compagnia: contratto.compagnia,
                        decorrenza: contratto.decorrenza,
                        scadenza: contratto.scadenza
                    };
                });
            },
            TranscodificaData(data) {
                if (commonLib.isEmpty(data)) return "";

                data = data.substring(0,10);
                var elements = data.split("-");
                var gg = elements[2].padStart(2,'0');
                var mm = elements[1].padStart(2,'0');
                var aa = elements[0];

                return gg+"/"+mm+"/"+aa;
            },
            elencoQuietanze(prodotto_id) {
                var contratto = this.elenco_contratti_quietanze.find(q => {
                    return q.prodotto_id === prodotto_id;
                });

                console.log("contratto:",contratto);
                console.log("quietanze:",contratto.quietanze);

                return contratto.quietanze;
            },
            onClickIndietro() {
                this.$router.push({name : "Pratiche.ElencoPratiche"});
            },
            async onClickSchedaPratica() {
                let id_pratica = this.RecordSelezionato.numero_interno;
                await this.fetchDatiCliente(id_pratica);
                this.$router.push({name : "Pratiche.SchedaPratica"});
            },
            onProblemiSegnalati() {
                this.$router.push({name : "Pratiche.ProblemiSegnalati"});
            },
            onSegnalaProblemiASede() {
                this.$router.push({name : "Pratiche.SegnalaProblemiASede"});
            },
            onControlloRichiestaEmissione() {
                if (this.isSede) {
                    this.clearElencoDocumentiPratica();
                    this.step = 1;
                    this.$router.push({name : "Pratiche.ControlloRichiestaEmissione"});
                }
            },
            onClickCambiaStatoPratica() {
                this.$router.push({name : "Pratiche.CambiaStatoPratica"});
            },
            onCancellaPratica() {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, annulla"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler annullare questa pratica?',
                }).onOk(async () => {
                    this.$router.push({name : "Pratiche.AnnullaPratica"});
                });

            },
            onDefinisciPratica() {
                this.$router.push({name : "Pratiche.DefinisciPratica"});
            },
            onPagamentiRicevute() {
                this.$router.push({name : "Pratiche.ElencoPagamenti"});
            },
            onInLavorazione() {
                this.$q.dialog({
                    title: 'Attenzione',
                    message: 'IN COSTRUZIONE'
                });
            },
            async GeneraDichiarazioniContraente() {
                var id = this.RecordSelezionato.numero_interno;
                var myurl = await this.fetchUrlDichiarazioniContraente(id);
                this.urlDichiarazioniContraente = myurl;

                return myurl;
            },
            async onRigeneraDichiarazioniContraente() {
                var url_pdf_dichiarazioni = this.urlDichiarazioniContraente;
                this.is_loading = true;

                //if(myurl === "") {
                    url_pdf_dichiarazioni = await this.GeneraDichiarazioniContraente();
                //}

                await axios({
                    url:url_pdf_dichiarazioni,
                    method:'GET',
                    responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    const status = exportFile('dichiarazioniContraente.pdf', response.data)
                    if (status === true) {
                        console.log('Generato documentoPreContrattuale.pdf', status);
                    } else {
                        // browser denied it
                        console.error('Errore generazione dichiarazioniContraente.pdf', status);
                    }
                });

                //this.isEmailDisabled = false;
                this.$store.dispatch("gestioneClienti/setEmailDisabled",false);

                this.is_loading = false;
            },
            onDefinisciQuietanza(compagnia,quietanza) {
                this.quietanza_da_rinnovare = quietanza;
                this.quietanza_da_rinnovare.compagnia = compagnia;

                this.$router.push({name : "Rinnovi.DefinisciQuietanza"});
            },
            async onStampaRicevuta(compagnia,quietanza) {
                console.log(compagnia,quietanza);

                var idquietanza = quietanza.idquietanza;
                var guid_pratica = this.RecordSelezionato.numero_interno;
                var _create = quietanza.data_incasso;

                var url = await this.fetchUrlRicevutaPagamentoQuietanza(
                    {
                        NumeroPratica: guid_pratica,
                        IDQuietanza: idquietanza,
                        create_at: _create
                    });

                var filename = "ricevuta_quietanza_"+_create;

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', filename+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            PuoLavorareLaPratica() {
                // return policy.AbilitaLavorazionePreventivi();
                return policy.isSede();
            },
            PuoCancellareLaPratica() {
                if (!this.isSede) return false;

                return policy.PuoCancellarePraticheProprie() ||
                       policy.PuoCancellarePraticheGruppo() ||
                       policy.PuoCancellareTutteLePratiche();
            },
            onModificaDatiContratti() {
                this.$router.push({name : "Pratiche.ModificaDatiContratto"});
            },
            onDocumentiContratto() {
                this.$router.push({name : "Pratiche.DocumentiDeiContratti"});
            },
            ...mapActions({
                fetchDatiCliente: "gestionePratiche/fetchDatiClientePratica",
                fetchUrlDichiarazioniContraente: "gestioneDocumenti/fetchUrlDichiarazioniContraente",
                setAnnullaPratica: "gestionePratiche/setAnnullaPratica",
                fetchNotePratica: "gestionePratiche/fetchNotePratica",
                fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
                fetchDatiEconomiciPratica: "gestionePratiche/fetchDatiEconomiciPratica",
                clearElencoDocumentiPratica: "gestioneDocumenti/clearElencoDocumentiPratica",
                fetchDatiUfficioDaPratica: "gestionePratiche/fetchDatiUfficioDaPratica",
                fetchElencoContrattiQuietanze: "gestionePratiche/fetchElencoContrattiQuietanze",
                fetchUrlRicevutaPagamentoQuietanza: "gestioneDocumenti/fetchUrlRicevutaPagamentoQuietanza"
            }),
        },
        async mounted() {
            window.scrollTo(0,0);
            this.annotazione = "";

            //await this.fetchDatiPreventivo(this.formPratica.preventivo.id);
            //console.log("mounted formPreventivo:",this.formPreventivo);

            if (!commonLib.isEmpty(this.formPratica)) {
                var num_pratica = this.formPratica.preventivo.id;
                this.annotazione = await this.fetchNotePratica(num_pratica);
                await this.fetchDatiPratica({ numero_pratica: num_pratica, token: "" });
            }

            this.dati_economici = await this.fetchDatiEconomiciPratica(num_pratica);
            this.dati_ufficio = await this.fetchDatiUfficioDaPratica(num_pratica);
            this.elenco_contratti_quietanze = await this.fetchElencoContrattiQuietanze(num_pratica);

            console.log("elenco contratti:",this.elenco_contratti_quietanze);

        },
    /*    async activated() {
            this.annotazione = "";

            if (!commonLib.isEmpty(this.formPratica)) {
                var num_pratica = this.formPratica.preventivo.id;
                this.annotazione = await this.fetchNotePratica(num_pratica);
                await this.fetchDatiPratica({ numero_pratica: num_pratica, token: "" });

                //await this.fetchDatiPreventivo(this.formPratica.preventivo.id);
            //    console.log("activated formPreventivo:",this.formPreventivo);
            }

            this.dati_economici = await this.fetchDatiEconomiciPratica(num_pratica);

        } */
    }
</script>

<style scoped>
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }
    .table-bordered td, .table th {
        border: 1px solid #a0a0a0;
        padding: 6px;
        margin: 2px;
        max-height: 10px;
    }
    .table-bordered th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #F0F0F0;
        border: 1px SOLID #404040;

    }
</style>
