import { render, staticRenderFns } from "./MenuGestionePratiche.vue?vue&type=template&id=1c4d5264&scoped=true&"
import script from "./MenuGestionePratiche.vue?vue&type=script&lang=js&"
export * from "./MenuGestionePratiche.vue?vue&type=script&lang=js&"
import style0 from "./MenuGestionePratiche.vue?vue&type=style&index=0&id=1c4d5264&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c4d5264",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QTable,QTr,QTh,QTd,QBtn});
